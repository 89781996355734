import React from "react"



const Header = () => {
    return (
        <div className="header-wrapper">
            <div className="main-info">

                <h1>Mansur Saleem</h1>
                <h2><i>Junior Web Developer</i></h2>
                

            
            </div>
        </div>
    )
}

export default Header
